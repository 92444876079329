<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">
        Promo - {{ id > 0 ? 'Edit' : 'Tambah' }}
      </span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">
          / Promo Detail / {{ id > 0 ? 'Edit' : 'Tambah' }}
        </span>
      </div>
    </div>
    <form @submit.prevent="save(!v$.$invalid)">
      <div class="card bg-white">
        <div class="flex justify-content-end mb-2">
          <Button
            :loading="isLoadingSave"
            icon="pi pi-save"
            class="mr-2"
            :label="id > 0 ? 'Update' : 'Simpan'"
            type="submit"
            :disabled="form.status === 'expired'"
          />
          <Button
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Item"
            @click="onAddData"
            :disabled="form.status === 'expired'"
          />
          <Button
            class="p-button-link"
            label="Kembali"
            @click="$router.push({ name: 'Promo' })"
          />
        </div>
        <div class="grid">
          <div class="col-12 md:col-8">
            <div class="formgrid grid">
              <div class="field col-12 md:col-2">
                <label>Kode Promo</label>
                <InputText
                  v-model="form.kode"
                  class="w-full"
                  input-class="w-full"
                  maxlength="50"
                  disabled
                />
              </div>
              <div class="field col-12 md:col-6">
                <label>Deskripsi</label>
                <InputText
                  v-model="form.deskripsi"
                  class="w-full"
                  input-class="w-full"
                  maxlength="50"
                />
                <small
                  v-if="
                    (v$.form.deskripsi.$invalid && submitted) ||
                    v$.form.deskripsi.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.deskripsi.required.$message }}</small
                >
              </div>

              <div class="field col-12 md:col-4">
                <label>Supplier</label>
                <Dropdown
                  v-model="form.supp_id"
                  :filter="true"
                  :options="list_supplier"
                  class="w-full"
                  input-class="w-full"
                  optionValue="id"
                  optionLabel="name"
                  placeholder="Pilih Supplier"
                  :disabled="form.status === 'expired'"
                  :class="{
                    'p-invalid': v$.form.supp_id.$invalid && submitted,
                  }"
                />
                <small
                  v-if="
                    (v$.form.supp_id.$invalid && submitted) ||
                    v$.form.supp_id.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.supp_id.required.$message }}</small
                >
              </div>

              <div class="field col-6 md:col-2">
                <label>Tgl Mulai </label>
                <Calendar
                  v-model="form.start_at"
                  dateFormat="dd/mm/yy"
                  class="w-full"
                  :class="{
                    'p-invalid': v$.form.start_at.$invalid && submitted,
                  }"
                  input-class="w-full"
                />
                <small
                  v-if="
                    (v$.form.start_at.$invalid && submitted) ||
                    v$.form.start_at.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.start_at.required.$message }}</small
                >
              </div>
              <div class="field col-6 md:col-2">
                <label>Jam Mulai</label>
                <Calendar
                  v-model="form.start_attime"
                  timeOnly
                  showSeconds
                  class="w-full"
                  input-class="w-full"
                />
              </div>
              <div class="field col-6 md:col-2">
                <label>Tgl Berakhir</label>
                <Calendar
                  v-model="form.end_at"
                  dateFormat="dd/mm/yy"
                  :minDate="form.start_at"
                  class="w-full"
                  :class="{
                    'p-invalid': v$.form.end_at.$invalid && submitted,
                  }"
                  input-class="w-full"
                />
                <span
                  v-if="
                    (v$.form.end_at.$invalid && submitted) ||
                    v$.form.end_at.$pending.$response
                  "
                >
                  <small
                    v-if="v$.form.end_at.required.$invalid"
                    class="p-error"
                    >{{ v$.form.end_at.required.$message }}</small
                  >
                  <small v-else class="p-error">{{
                    v$.form.end_at.minValue.$message
                  }}</small>
                </span>
              </div>
              <div class="field col-6 md:col-2">
                <label>Jam Berakhir</label>
                <Calendar
                  v-model="form.end_attime"
                  timeOnly
                  showSeconds
                  :minDate="form.start_attime"
                  class="w-full"
                  input-class="w-full"
                />
                <span
                  v-if="
                    (v$.form.end_attime.$invalid && submitted) ||
                    v$.form.end_attime.$pending.$response
                  "
                >
                  <small class="p-error">{{
                    v$.form.end_attime.minValue.$message
                  }}</small>
                </span>
              </div>
              <div class="field col-12 md:col-2">
                <label>Status Promo</label>
                <div>
                  <div
                    :class="'status-badge status-' + status"
                    style="text-transform: capitalize"
                  >
                    {{ status }}
                  </div>
                </div>
              </div>
              <div class="field col-12 md:col-2">
                <label>Promo</label>
                <div>
                  <input
                    :checked="form.active"
                    type="checkbox"
                    id="active"
                    @click="OnCheckClick"
                  /><label for="active">Aktif</label>
                </div>
              </div>
              <div class="field col-12 md:col-6">
                <div>
                  <label>DISKON</label>
                  <div class="flex">
                    <div class="p-inputgroup flex-1">
                      <span
                        v-if="form.disc_all > 100"
                        class="p-inputgroup-addon"
                        >Rp</span
                      >
                      <InputNumber
                        v-model="form.disc_all"
                        :minFractionDigits="2"
                        mode="decimal"
                        locale="id-ID"
                        @input="form.disc_all = $event.value"
                      />
                      <span
                        v-if="form.disc_all <= 100"
                        class="p-inputgroup-addon"
                        >%</span
                      >
                    </div>
                    <Button
                      :disabled="form.status === 'expired'"
                      icon="pi pi-sync"
                      class="p-button mr-2"
                      label="Terapkan"
                      @click="sysSameDisc"
                    />
                  </div>
                </div>
              </div>
              <div class="field col-12 md:col-2">
                <label></label>
                <div>
                  <input
                    :checked="form.claim"
                    type="checkbox"
                    id="claim"
                    @click="OnCheckClaim"
                  /><label for="claim">claim</label>
                </div>
              </div>
              <div class="field col-12 md:col-4">
                <label>No Kontra</label>
                <div>
                  <Chips
                    v-model="form.no_kontra"
                    class="w-full"
                    input-class="w-full"
                    :disabled="!form.claim"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <DataTable
            v-model:selection="selected"
            v-model:filters="filters"
            :value="form.details"
            :loading="isLoading"
            showGridlines
            filterDisplay="row"
            responsiveLayout="scroll"
            class="p-datatable-sm"
          >
            <Column selectionMode="multiple" headerStyle="width: 3em" />
            <!-- <template #columns> -->
            <Column
              field="item.barcode_1"
              header="Barcode"
              style="min-width: 12rem"
              :showFilterMenu="false"
            >
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  v-model="filterModel.value"
                  type="text"
                  class="p-column-filter"
                  placeholder="Search"
                  @input="filterCallback()"
                />
              </template>
            </Column>
            <Column
              field="item.name"
              header="DESKRIPSI"
              style="min-width: 16rem"
              :showFilterMenu="false"
            >
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  v-model="filterModel.value"
                  type="text"
                  class="p-column-filter"
                  placeholder="Search"
                  @input="filterCallback()"
                />
              </template>
            </Column>
            <Column
              field="harga"
              header="Harga Normal"
              style="
                min-width: 12rem;
                max-width: 12rem;
                justify-content: flex-end;
              "
            >
              <template #body="slotProps">
                <span style="text-align: right">
                  {{ formatCurrency(slotProps.data.harga) }}
                </span>
              </template>
            </Column>
            <Column
              field="disc"
              header="Potongan/DISC(%)"
              style="
                min-width: 12rem;
                max-width: 12rem;
                justify-content: flex-end;
              "
            >
              <template #body="slotProps">
                <span style="text-align: right">
                  {{
                    slotProps.data.disc == null || slotProps.data.disc > 100
                      ? formatCurrency(slotProps.data.disc)
                      : `${slotProps.data.disc}%`
                  }}
                </span>
              </template>
            </Column>
            <Column
              field="harga_promo"
              header="Harga Promo"
              style="
                min-width: 12rem;
                max-width: 12rem;
                justify-content: flex-end;
              "
            >
              <template #body="slotProps">
                <span style="text-align: right">
                  {{ formatCurrency(slotProps.data.harga_promo) }}
                </span>
              </template>
            </Column>
            <Column
              style="flex-grow: 1; justify-content: flex-end; min-width: 8rem"
            >
              <template #body="{ data }">
                <Button
                  :disabled="form.status === 'expired'"
                  type="button"
                  icon="pi pi-pencil"
                  class="p-button-outlined p-button-secondary p-button-text mr-2"
                  @click="onEditData(data)"
                />
                <Button
                  :disabled="form.status === 'expired'"
                  type="button"
                  icon="pi pi-trash"
                  class="p-button-outlined p-button-danger p-button-text"
                  @click="onConfirmDeletion(data)"
                />
              </template>
            </Column>
            <!-- </template> -->
          </DataTable>
        </div>
      </div>
    </form>
    <Dialog
      :header="
        formDetail && formDetail.item_id > 0
          ? 'Edit Item promo'
          : 'Tambah Item promo'
      "
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '70vw', height: '80vw' }"
      :modal="true"
      :maximizable="true"
    >
      <form-promo
        :item="formDetail"
        :loading="isLoadingSave"
        :excludeList="exclude_list"
        :supp-Id="form.supp_id"
        @save="onSaveData"
        @close="dialog = false"
      />
    </Dialog>
    <Dialog
      header="Edit Item promo"
      v-model:visible="dialogItem"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      :modal="true"
      :maximizable="true"
    >
      <form-promo-edit
        :item="formDetail"
        :loading="isLoadingSave"
        :list-items="list_items"
        :editMode="editMode"
        @save="onSaveDataItem"
        @close="dialogItem = false"
      />
    </Dialog>
    <Dialog
      header="Hapus Promo"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Item Promo <strong>{{ formDetail.item.name }}</strong> akan dihapus.
          Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteItemPromo"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import PromoService from '@/services/PromoService'
import SupplierService from '@/services/SupplierService'
import ItemSupplierService from '@/services/ItemSupplierService'
import FormPromo from '@/components/promo/FormPromo'
import FormPromoEdit from '@/components/promo/FormPromoEdit'
// import FxTable from '@/components/FxTable'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'
import { formatCurrency } from '@/helpers/index'
import useVuelidate from '@vuelidate/core'
import { helpers, required, minValue, sameAs } from '@vuelidate/validators'
import dayjs from 'dayjs'

const startDateTime = new Date()
startDateTime.setHours(0, 0, 0, 0)
const stopDateTime = new Date()
stopDateTime.setHours(23, 59, 59, 999)

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: 'Promo',
  components: {
    FormPromo,
    FormPromoEdit,
    // FxTable,
    Hotkey,
  },
  data() {
    return {
      today: new Date(),
      selected: null,
      id: parseInt(this.$route.params.id),
      submitted: false,
      promoService: null,
      supplierService: null,
      itemSupplierService: null,
      dialog: false,
      dialogItem: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      editMode: false,
      items: [],
      list_items: [],
      list_items_supplier: [],
      list_supplier: [],
      filters: {
        'item.barcode_1': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'item.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      list_status: [
        { value: 'planning', label: 'Planning', level: 0 },
        { value: 'ongoing', label: 'Ongoing', level: 1 },
        { value: 'expired', label: 'Expired', level: 2 },
      ],
      form: {
        supp_id: '',
        start_at: new Date(),
        start_attime: startDateTime,
        end_at: new Date(),
        end_attime: stopDateTime,
        kode: '',
        deskripsi: '',
        status: 'planning',
        active: true,
        disc_all: 0,
        no_kontra: '',
        claim: false,
        details: [],
      },
      formDetail: {
        item_id: 0,
        harga: 0,
        disc: 0,
        harga_promo: 0,
      },
      isCheck: false,
      listDeleted: [],
      Promo: [],
      containModesOption: [
        { label: 'Contains', value: FilterMatchMode.CONTAINS },
      ],
      equalModesOption: [{ label: 'Equals', value: FilterMatchMode.EQUALS }],
    }
  },
  computed: {
    exclude_list() {
      return this.form.details.map((x) => `${x.item_id}`)
    },
    status() {
      const today = new Date()
      const startDatetime = new Date(
        `${dayjs(this.form.start_at).format('YYYY-MM-DD')} ${dayjs(
          this.form.start_attime || startDateTime
        ).format('HH:mm:ss')}`
      )

      const stopDatetime = new Date(
        `${dayjs(this.form.end_at).format('YYYY-MM-DD')} ${dayjs(
          this.form.end_attime || stopDateTime
        ).format('HH:mm:ss')}`
      )

      const isDateInRange = today >= startDatetime && today <= stopDatetime
      const isDateAboveRange = today > stopDatetime

      let status = 'planning'

      if (isDateInRange) {
        status = 'ongoing'
      } else if (isDateAboveRange) {
        status = 'expired'
      }

      return status
    },
  },

  created() {
    this.promoService = new PromoService()
    this.supplierService = new SupplierService()
    this.itemSupplierService = new ItemSupplierService()
  },
  async mounted() {
    if (this.id > 0) {
      this.loadData()
    }
    this.supplierService
      .getActive()
      .then((res) => {
        this.list_supplier = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Supplier', this)
      })
      .finally(() => (this.isLoading = false))
  },
  methods: {
    loadData() {
      this.isLoading = true
      this.promoService
        .getById(this.id)
        .then((res) => {
          this.form = res.data.data
          this.form.start_attime = this.form.start_at
            ? new Date(this.form.start_at)
            : ''
          this.form.end_attime = this.form.end_at
            ? new Date(this.form.end_at)
            : ''
          this.form.start_at = this.form.start_at
            ? new Date(dayjs(this.form.start_at).format('YYYY-MM-DD'))
            : ''
          this.form.end_at = this.form.end_at
            ? new Date(dayjs(this.form.end_at).format('YYYY-MM-DD'))
            : ''
          this.form.disc_all = 0
          this.form.no_kontra = this.form.no_kontra
            ? this.form.no_kontra.split(',').map((item) => item.trim())
            : ''

          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Promo', this)
        })
        .finally(() => (this.isLoading = false))
    },
    OnCheckClick() {
      this.form.active = this.form.active == false ? true : false
    },
    OnCheckClaim() {
      this.form.claim = this.form.claim == false ? true : false
      if (!this.form.claim) {
        this.form.no_kontra = ''
      }
    },
    convertToIdr(harga, disc) {
      return Math.round(harga * disc) / 100
    },
    sysSameDisc() {
      if (!this.selected || this.selected.length === 0) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Promo',
          detail: 'Pilih Item terlebih dahulu',
          life: 3000,
        })
        return
      }
      this.selected.forEach((item) => {
        let disc = 0

        if (this.form.disc_all <= 100) {
          disc = parseFloat(
            (item.harga * (this.form.disc_all / 100.0)).toFixed(2)
          )
        } else {
          disc = parseFloat(this.form.disc_all.toFixed(2))
        }

        item.disc = this.form.disc_all
        let harga_promo = item.harga - disc
        harga_promo = Math.ceil(harga_promo / 100) * 100

        item.harga_promo = harga_promo > 0 ? harga_promo : 0
      })
    },
    save(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      const deleted = this.id ? { deleted: this.listDeleted } : {}

      const start_at = this.form.start_at
        ? {
            start_at: `${dayjs(this.form.start_at).format(
              'YYYY-MM-DD'
            )} ${dayjs(this.form.start_attime || startDateTime).format(
              'HH:mm:ss'
            )}`,
          }
        : {}
      const end_at = this.form.end_at
        ? {
            end_at: `${dayjs(this.form.end_at).format('YYYY-MM-DD')} ${dayjs(
              this.form.end_attime || stopDateTime
            ).format('HH:mm:ss')}`,
          }
        : {}
      const formattedNoKontra = this.form.no_kontra ? this.form.no_kontra.join(',') : ''
      const form = {
        ...start_at,
        ...end_at,
        supp_id: this.form.supp_id,
        deskripsi: this.form.deskripsi,
        kode: this.form.kode,
        status: this.form.status,
        active: this.form.active,
        claim: this.form.claim,
        no_kontra: formattedNoKontra,
        details: this.form.details.map((item) => {
          const id = item.id ? { id: item.id } : this.id ? { id: 0 } : {}
          return {
            ...id,
            item_id: item.item_id,
            harga: item.harga,
            disc: item.disc,
            harga_promo: item.harga_promo,
          }
        }),
        ...deleted,
      }

      if (!this.id) {
        this.isLoadingSave = true
        this.promoService
          .add(form)
          .then((res) => {
            if (res.data.status === 1) {
              this.$toast.add({
                severity: 'success',
                summary: 'Promo',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })

              this.$router.push({ name: 'Promo' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Promo', this)
          })
          .finally(() => {
            this.deleted = []
            this.isLoadingSave = false
          })
      } else {
        this.isLoadingSave = true
        this.promoService
          .update(this.id, form)
          .then((res) => {
            if (res.data.status === 1) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Promo',
                detail: 'Data berhasil diupdate.',
                life: 3000,
              })
              this.selected = null
            }
          })
          .catch((err) => {
            errorHandler(err, 'Promo', this)
          })
          .finally(() => {
            this.isLoadingSave = false
          })
      }
    },
    formatCurrency(val) {
      return formatCurrency(val, 0, 0)
    },
    onEditData(data) {
      this.editMode = true
      this.formDetail = Object.assign({}, data)
      this.dialogItem = true
    },
    onAddData() {
      if (!this.form.supp_id) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Promo',
          detail: 'Supplier harus diisi terlebih dahulu.',
          life: 3000,
        })
        return
      }

      this.editMode = false
      this.formDetail = {
        item_id: 0,
        harga: 0,
        disc: 0,
        harga_promo: 0,
      }
      this.dialog = true
    },
    async onSaveDataItem(data) {
      const detailItem = Object.assign({}, data)
      const idx = this.form.details.findIndex(
        (item) => item.item_id === detailItem.item_id
      )
      if (this.editMode) {
        Object.assign(this.form.details[idx], { ...detailItem })
        this.dialogItem = false
      }
    },
    async onSaveData(data) {
      data.forEach((detailItem) => {
        const idx = this.form.details.findIndex(
          (item) => item.item_id === detailItem.id
        )

        if (idx === -1) {
          let disc = parseFloat(detailItem.disc)
          let price = parseFloat(detailItem.price)
          let harga_promo = parseFloat(detailItem.price)

          if (disc >= 1.0 && disc <= 100.0) {
            let disc_v = parseFloat((price * (disc / 100.0)).toFixed(2))
            harga_promo -= disc_v
          } else if (disc > 100.0) {
            harga_promo -= disc
          }

          harga_promo = Math.ceil(Math.max(harga_promo, 0) / 100) * 100

          this.form.details.push({
            item: detailItem,
            item_id: detailItem.id,
            disc: detailItem.disc,
            harga_promo: harga_promo,
            harga: detailItem.price,
          })
          this.$toast.add({
            severity: 'success',
            summary: 'Promo',
            detail: 'Data Item berhasil ditambahkan.',
            life: 3000,
          })
          this.dialog = false
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'Promo',
            detail: `Item ${detailItem.name} sudah ada`,
            life: 3000,
          })
        }
      })
    },
    onConfirmDeletion(data) {
      this.formDetail = data
      this.dialogHapus = true
    },
    onDeleteItemPromo() {
      const index = this.form.details.findIndex(
        (el) => el.item_id === this.formDetail.item_id
      )
      if (index !== -1) {
        if (this.id && this.form.details[index].id) {
          this.listDeleted.push(this.form.details[index].id)
        }
        this.form.details.splice(index, 1)
        this.$toast.add({
          severity: 'success',
          summary: 'Data Item Promo',
          detail: 'Data berhasil dihapus.',
          life: 3000,
        })
      }
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },
  },
  validations() {
    return {
      form: {
        start_at: {
          required: helpers.withMessage('Tgl Mulai harus diisi.', required),
        },
        end_at: {
          required: helpers.withMessage('Tgl Berakhir harus diisi.', required),
          minValue: helpers.withMessage(
            'Tgl Berakhir harus lebih dari Tgl Mulai',
            minValue(this.form.start_at) || sameAs(this.form.start_at)
          ),
        },
        end_attime: {
          minValue: helpers.withMessage(
            'Jam Berakhir harus lebih dari Jam Mulai',
            minValue(this.form.start_attime) || sameAs(this.form.start_attime)
          ),
        },
        supp_id: {
          required: helpers.withMessage('Supplier harus diisi.', required),
        },

        deskripsi: {
          required: helpers.withMessage('Deskripsi harus diisi.', required),
        },
      },
    }
  },
}
</script>
