<template>
  <div>
    <form
      @submit.prevent="handleSubmit(!v$.$invalid)"
      @keyup.enter="handleSubmit(!v$.$invalid)"
    >
      <div class="grid">
        <div class="col-12">
          <div class="formgrid grid">
            <div class="field col-12 md:col-12">
              <label>Item</label>
              <Dropdown
                v-model="form.item_id"
                :filter="true"
                :options="[form.item]"
                class="w-full"
                input-class="w-full"
                optionValue="id"
                autofocus
                optionLabel="name"
                placeholder="Pilih Item"
                :disabled="editMode"
                :class="{ 'p-invalid': v$.form.item_id.$invalid && submitted }"
                @change="selectItem"
              />
              <small
                v-if="
                  (v$.form.item_id.$invalid && submitted) ||
                  v$.form.item_id.$pending.$response
                "
                class="p-error"
                >{{ v$.form.item_id.required.$message }}</small
              >
            </div>

            <div class="field col-6 md:col-6">
              <label>Harga</label>
              <div class="p-inputgroup flex-1">
                <span class="p-inputgroup-addon"> Rp </span>
                <InputNumber
                  v-model="form.harga"
                  :class="{
                    'p-invalid': v$.form.harga.$invalid && submitted,
                  }"
                  locale="id-ID"
                  :min="0"
                  :min-fraction-digits="0"
                  :max-fraction-digits="2"
                  class="w-full"
                  disabled
                  input-class="w-full"
                />
              </div>
              <small
                v-if="
                  (v$.form.harga.$invalid && submitted) ||
                  v$.form.harga.$pending.$response
                "
                class="p-error"
              >
                <span v-if="v$.form.harga.required.$invalid">
                  {{ v$.form.harga.required.$message }}
                </span>
                <span v-else> {{ v$.form.harga.numeric.$message }}</span>
              </small>
            </div>

            <div class="field col-6 md:col-6">
              <label>Potongan/Disc</label>
              <div class="p-inputgroup flex-1">
                <span v-if="form.disc > 100" class="p-inputgroup-addon">
                  Rp
                </span>
                <InputNumber
                  ref="disc"
                  v-model.number="form.disc"
                  autofocus
                  :class="{
                    'p-invalid': v$.form.disc.$invalid && submitted,
                  }"
                  locale="id-ID"
                  :min="0"
                  :max="form.harga"
                  :minFractionDigits="0"
                  :maxFractionDigits="2"
                  class="w-full"
                  input-class="w-full"
                  @input="inputSatuan($event)"
                  @focus="selectAllText('disc')"
                />
                <span v-if="form.disc <= 100" class="p-inputgroup-addon">
                  %
                </span>
              </div>
              <small
                v-if="
                  (v$.form.disc.$invalid && submitted) ||
                  v$.form.disc.$pending.$response
                "
                class="p-error"
              >
                <span v-if="v$.form.disc.required.$invalid">
                  {{ v$.form.disc.required.$message }}
                </span>
                <span v-else> {{ v$.form.disc.numeric.$message }}</span>
              </small>
              <small v-else-if="form.disc === 0"
                >* Pilih item terlebih dahulu</small
              >
            </div>
            <div class="field col-6 md:col-6">
              <label>Harga Promo</label>
              <div class="p-inputgroup flex-1">
                <span class="p-inputgroup-addon"> Rp </span>
                <InputNumber
                  v-model.number="form.harga_promo"
                  locale="id-ID"
                  :minFractionDigits="0"
                  :maxFractionDigits="2"
                  class="w-full"
                  input-class="w-full"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          :label="editMode ? 'Ubah' : 'Tambahkan'"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required, numeric } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    listSatuan: {
      type: Array,
      default() {
        return []
      },
    },
    listItems: {
      type: Array,
      default() {
        return []
      },
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    onMenu: {
      type: String,
      default: 'order',
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  watch: {
    'form.disc'(value) {
      if (value > 0 && this.form.harga) {
        let disc = parseFloat(this.form.disc)
        let harga = parseFloat(this.form.harga)
        let harga_promo = harga

        let disc_v = 0

        if (disc >= 1.0 && disc <= 100.0) {
          disc_v = parseFloat((harga * (disc / 100.0)).toFixed(2))
        } else if (disc > 100.0) {
          disc_v = parseFloat(disc.toFixed(2))
        }

        harga_promo -= disc_v
        // Round up to the nearest hundred
        harga_promo = Math.ceil(harga_promo / 100) * 100

        this.form.harga_promo = harga_promo > 0 ? harga_promo : 0
      }
    },
  },
  computed: {
    form() {
      return this.item
    },
    listSatuanItem() {
      return this.listSatuan.filter(
        (item) => item.sat === this.form.unit_s || item.sat === this.form.unit_b
      )
    },
  },
  methods: {
    selectAllText(event) {
      if (event) {
        this.$refs[event].$el.querySelector('input').select()
      }
    },
    convertToIdr(value) {
      return Math.round(this.form.harga * value) / 100
    },
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      this.$emit('save', { ...this.form })
    },
    inputSatuan(event) {
      this.form.disc = event.value
    },
    selectItem(event) {
      const idx = this.listItems.findIndex((item) => item.id === event.value)
      if (idx !== -1) {
        this.form.harga = this.listItems[idx].price
        this.form.disc = 0
      }
    },
  },
  validations() {
    return {
      form: {
        item_id: {
          required: helpers.withMessage('Items harus diisi.', required),
        },
        harga: {
          required: helpers.withMessage('Harga harus diisi.', required),
          numeric: helpers.withMessage(
            'Harga harus harus diisi dengan angka.',
            numeric
          ),
        },
        disc: {
          required: helpers.withMessage('Disc harus diisi.', required),
          numeric: helpers.withMessage(
            'Disc harus harus diisi dengan angka.',
            numeric
          ),
        },
      },
    }
  },
}
</script>
