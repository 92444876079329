<template>
  <div>
    <form @submit.prevent="handleSubmit()">
      <div class="grid">
        <!-- <div class="col-12">
          <div class="field col-12 md:col-12">
            <label>Item </label>
            <AutoComplete
              ref="item"
              v-model="selectedItem"
              class="w-full"
              input-class="w-full"
              placeholder="Search here"
              :suggestions="newListItems"
              field="name"
              @complete="searchItemPromo"
              @item-select="selectItem"
              @blur="handleBlur"
              multiple
            >
              <template #item="slotProps">
                <div>
                  <b>{{ slotProps.item.sku }}</b>
                </div>
                <div>{{ slotProps.item.name }}</div>
              </template>
            </AutoComplete>
          </div>
        </div> -->
        <div class="col-12">
          <div class="field col-12 md:col-12">
            <label>Item </label>
            <Listbox
              v-model="selectedItem"
              placeholder="Search here"
              :options="newListItems"
              option-label="name"
              class="w-full scrollable-listbox"
              filter
              @filter="searchItemPromo"
              @change="selectItem"
            >
              <template #item="slotProps">
                <div>
                  <b>{{ slotProps.item.sku }}</b>
                </div>
                <div>{{ slotProps.item.name }}</div>
              </template>
            </Listbox>
          </div>
        </div>
        <div class="col-12">
          <DataTable
            :value="listItemSelected"
            dataKey="id"
            responsiveLayout="scroll"
            class="p-datatable-sm"
            row-hover
            paginator
            filterDisplay="row"
            :rows="5"
            :rowsPerPageOptions="[5, 10, 25, 50]"
            :totalRecords="listItemSelected.length"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
            editMode="cell"
            @cell-edit-complete="onCellEditComplete"
          >
            <template #empty>
              <div class="text-center">Tidak ada item yang dipilih</div>
            </template>
            <template #loading>
              <div class="text-center">Memuat data, harap tunggu...</div>
            </template>
            <Column style="flex-grow: 1; justify-content: flex-start">
              <template #body="{ data }">
                <Button
                  type="button"
                  icon="pi pi-trash"
                  class="p-button-outlined p-button-danger p-button-text"
                  @click="onDeletion(data)"
                />
              </template>
            </Column>
            <Column field="category" header="Kategori" style="min-width: 12rem">
            </Column>
            <Column field="name" header="Item" style="min-width: 15rem">
            </Column>

            <Column field="price" header="Harga" style="min-width: 8rem">
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data.price) }}
              </template>
            </Column>
            <Column
              field="disc"
              header="Potongan/DISC(%)"
              style="min-width: 8rem"
            >
              <template #editor="{ data }">
                <div class="p-inputgroup flex-1">
                  <span
                    v-if="data.disc > 100 || data.disc == null"
                    class="p-inputgroup-addon"
                  >
                    Rp
                  </span>
                  <InputNumber
                    v-model.number="data.disc"
                    locale="id-ID"
                    :min="0"
                    :max="data.price"
                    :minFractionDigits="0"
                    :maxFractionDigits="2"
                    class="w-full"
                    input-class="w-full"
                    @input="onDiscEditInput(data)"
                  />
                  <span v-if="data.disc <= 100" class="p-inputgroup-addon">
                    %
                  </span>
                </div>
              </template>
              <template #body="slotProps">
                {{
                  slotProps.data.disc == null || slotProps.data.disc > 100
                    ? formatCurrency(slotProps.data.disc)
                    : `${slotProps.data.disc} %`
                }}
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          :label="'Tambahkan'"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import errorHandler from '@/helpers/error-handler'
import { FilterMatchMode } from 'primevue/api'
import { formatCurrency } from '@/helpers/index'
import ItemSupplierService from '@/services/ItemSupplierService'
import ItemService from '@/services/ItemService'

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {},
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    listSatuan: {
      type: Array,
      default() {
        return []
      },
    },
    excludeList: {
      type: Array,
      default() {
        return []
      },
    },
    suppId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      submitted: false,
      itemService: null,
      itemSupplierService: null,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      active: 0,
      category: null,
      selected: [],
      listItemSelected: [],
      released_item: false,
      listItems: [],
      listItemSuppliers: [],
      selectedItem: null,
    }
  },
  created() {
    this.itemService = new ItemService()
    this.itemSupplierService = new ItemSupplierService()
  },
  computed: {
    form() {
      return this.item
    },
    listItemsEx() {
      if (this.suppId > 0) {
        const dataItem = this.released_item
          ? this.listItems
          : this.listItemSuppliers
        return dataItem.map((item) => ({
          ...item,
          disabled: this.excludeList.indexOf(`${item.id}`) !== -1,
        }))
      }
      return []
    },
    newListItems() {
      let newListWithDisable = this.listItemsEx.map((item) => {
        const isMatch = this.listItemSelected.some((dataToFind) =>
          Object.entries(dataToFind).every(
            ([key, value]) => item[key] === value
          )
        )
        return {
          ...item,
          disabled: item.disabled || isMatch,
        }
      })
      const newItem =
        this.active === 1
          ? newListWithDisable.filter(
              (item) => !item.disabled && this.category === item.ctg_id
            )
          : newListWithDisable
      return newItem
    },
  },
  methods: {
    formatCurrency(val) {
      return formatCurrency(val, 0, 0)
    },
    convertToIdr(value) {
      return Math.round(this.form.harga * value) / 100
    },
    OnCheckClick() {
      this.released_item = !this.released_item
    },
    searchItemPromo(event) {
      this.form.item_id = ''
      this.itemService
        .getListPromo(event.query, this.suppId)
        .then((res) => {
          this.listItemSuppliers = res.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Item', this)
        })
        .finally(() => (this.isLoading = false))
      this.query = event.query
      this.isLoading = false
    },
    onCellEditComplete(event) {
      let { data, newValue } = event
      const idx = this.listItemSelected.findIndex((item) => item.id === data.id)

      if (idx !== -1) {
        this.listItemSelected[idx].disc = newValue
      }
    },
    onDiscEditInput(data) {
      const idx = this.listItemSelected.findIndex((item) => item.id === data.id)
      if (idx !== -1) {
        this.listItemSelected[idx].disc = data.disc
      }
    },
    handleSubmit() {
      this.submitted = true

      if (this.listItemSelected.length === 0) {
        this.$toast.add({
          severity: 'error',
          summary: 'Promo',
          detail: 'Detail Item setidaknya ada 1 data',
          life: 3000,
        })
        return
      }

      this.$emit('save', this.listItemSelected)
      this.selected = []
      this.listItemSelected = []
    },
    // selectItem() {
    //   const dataItem = this.released_item
    //     ? this.listItems
    //     : this.listItemSuppliers
    //   const idx = dataItem.findIndex((item) => item.id === this.selectedItem.id)
    //   if (idx !== -1) {
    //     if (dataItem[idx].price > 0) {
    //       if (
    //         !this.listItemSelected.some((item) => item.id === dataItem[idx].id)
    //       ) {
    //         this.listItemSelected.push(dataItem[idx])
    //         this.selectedItem = null
    //       } else {
    //         this.$toast.add({
    //           severity: 'error',
    //           summary: 'Promo',
    //           detail: 'Item ' + this.selectedItem.name + ' sudah ada',
    //           life: 3000,
    //         })
    //         this.selectedItem = null
    //       }
    //     } else {
    //       this.$toast.add({
    //         severity: 'warn',
    //         summary: 'Item',
    //         detail: `item ${
    //           dataItem[idx].name
    //         } dengan harga ${this.formatCurrency(
    //           dataItem[idx].price
    //         )} tidak dapat dimasukan kedalam detail promo!`,
    //         life: 3000,
    //       })
    //     }
    //   }
    // },
    selectItem(event) {
      const dataItem = this.released_item
        ? this.listItems
        : this.listItemSuppliers
      const idx = dataItem.findIndex((item) => item.id === event.value.id)
      if (idx !== -1) {
        if (dataItem[idx].price > 0) {
          if (
            !this.listItemSelected.some((item) => item.id === dataItem[idx].id)
          ) {
            this.listItemSelected.push(dataItem[idx])
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Promo',
              detail: 'Item ' + dataItem[idx].name + ' sudah ada',
              life: 3000,
            })
          }
        } else {
          this.$toast.add({
            severity: 'warn',
            summary: 'Item',
            detail: `item ${
              dataItem[idx].name
            } dengan harga ${this.formatCurrency(
              dataItem[idx].price
            )} tidak dapat dimasukan kedalam detail promo!`,
            life: 3000,
          })
        }
      }
      this.selectedItem = null
    },
    handleBlur() {
      if (this.selectedItem) {
        this.selectItem({ value: this.selectedItem })
        this.selectedItem = null
      }
    },
    onDeletion(event) {
      const idx = this.listItemSelected.findIndex(
        (item) => item.id === event.id
      )
      if (idx !== -1) {
        this.listItemSelected.splice(idx, 1)
      }
    },
  },
  validations() {
    return {
      form: {},
    }
  },
}
</script>

<style scoped>
.scrollable-listbox {
  max-height: 200px; /* Adjust as needed */
  overflow-y: auto;
}
</style>
